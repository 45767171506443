import axios from '@services/HTTP'
import type { MSG } from 'app/api/discovery/chat/route'
import { useDiscoveryStore } from 'store/discovery'

export const askEvaroLog = async (msg: MSG) => {
  return axios.post('/connect/ask_evaro/log', msg, {
    baseURL: process.env.NEXT_PUBLIC_NODE_API_URL,
    headers: {
      Authorization: null
    }
  })
}

export const askEvaroLogClient = async (message: string, data?: string) => {
  return askEvaroLog({
    role: 'event',
    content: message,
    data,
    timestamp: Date.now(),
    threadID: useDiscoveryStore.getState().ID
  })
}