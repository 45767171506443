import { safeStorage } from '@utils/storage'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface PatientStore {
  patient: PatientForm
  setPatient: (patient: PatientForm) => void
  reset: () => void
  product_id: string | null
  questionnaire_id: string | null
  setProductId: (product_id: string) => void
  setQuestionnaireId: (questionnaire_id: string) => void
  answers: {
    [key: string]: string | string[]
  }
  exitAnswers: {
    [key: string]: string | string[]
  }
  flaggedAnswers: {
    [key: string]: string[]
  }
  hiddenAnswers: {
    [key: string]: string | string[]
  }
  setFlaggedAnswers: (questionHash: string, answerValue: string[]) => void
  setSingleAnswer: (questionHash: string, answerValue: string | string[]) => void
  setExitAnswers: (questionHash: string, answerValue: string | string[]) => void
  setHiddenAnswers: (questionHash: string, answerValue: string | string[]) => void
  patientHash: string | null
  setPatientHash: (patientHash: string) => void
  answerHash: string | null
  setAnswerHash: (answerHash: string) => void
  nhsNumber?: string
}

export interface PatientForm {
  firstName: string
  lastName: string
  dob: string
  gender: 'male' | 'female'
}

const initialState: Pick<
  PatientStore,
  | 'patient'
  | 'product_id'
  | 'questionnaire_id'
  | 'exitAnswers'
  | 'answers'
  | 'answerHash'
  | 'flaggedAnswers'
  | 'hiddenAnswers'
  | 'patientHash'
> = {
  patient: {
    firstName: '',
    lastName: '',
    dob: '',
    gender: null
  },
  product_id: null,
  questionnaire_id: null,
  answers: {},
  exitAnswers: {},
  flaggedAnswers: {},
  hiddenAnswers: {},
  patientHash: null,
  answerHash: null
}

export const usePatientStore = create<PatientStore>()(
  persist(
    (set, get) => ({
      ...initialState,
      setPatient: patient => set({ patient }),
      reset: () =>
        set({
          ...initialState,
          answers: {},
          exitAnswers: {},
          flaggedAnswers: {},
          hiddenAnswers: {},
          product_id: get().product_id,
          questionnaire_id: get().questionnaire_id
        }),
      setProductId: product_id => set({ product_id }),
      setQuestionnaireId: questionnaire_id => set({ questionnaire_id }),
      setSingleAnswer: (questionHash, answerValue) => {
        const currentAnswers = get().answers
        currentAnswers[questionHash] = answerValue
        set({ answers: currentAnswers })
      },
      setExitAnswers: (questionHash, answerValue) => {
        const currentAnswers = get().exitAnswers
        currentAnswers[questionHash] = answerValue
        set({ exitAnswers: currentAnswers })
      },
      setFlaggedAnswers: (questionHash, answerValue) => {
        const data = get().flaggedAnswers
        data[questionHash] = answerValue
        set({ flaggedAnswers: data })
      },
      setHiddenAnswers: (questionHash, answerValue) => {
        const data = get().hiddenAnswers
        data[questionHash] = answerValue
        set({ hiddenAnswers: data })
      },
      setPatientHash: patientHash => set({ patientHash }),
      setAnswerHash: answerHash => set({ answerHash })
    }),
    {
      name: 'patient_state',
      storage: createJSONStorage(() => safeStorage),
      version: 1,
      skipHydration: true
    }
  )
)
